@import "import";

//noinspection CssUnknownTarget
@import url('https://fonts.googleapis.com/css?family=Lato:400,700,900');


/**
  FARBEN
 */

$pink: #8fcb01;
$pinkHover: rgba(143, 203, 1, 0.85);
$dunkel-braun: $pink;
$hell-braunHover: $dunkel-braun;
$hell-braun: #00549d;
$blau: $hell-braun;



/**
  GENERAL
 */

$body--font-family: Lato;
$body--font-size: 15px;
$body--font-size-xs: 13px;
$body--font-weight: 300;
$body--font-color: #000000;

$h1--color: #8fcb01;
$h1--font-family: 'Lato';
$h1--line-height: 42px;
$h1--text-transform: none;
$h1--font-size: 40px;
$h1--font-weight: 100;
$h1--padding: 0px 0px 5px 0px;
$h1--text-shadow: 0.5px 0.5px 0px #fff;


$h2--color: $h1--color;
$h2--font-family: $h1--font-family;
$h2--line-height: $h1--line-height;
$h2--text-transform: $h1--text-transform;
$h2--font-size: $h1--font-size;
$h2--font-size-xs: 30px;
$h2--font-weight: $h1--font-weight;
$h2--padding: $h1--padding;
$h2--text-shadow: $h1--text-shadow;
$h2--text-align: left;

$h3--color: #00549d;
$h3--font-family: Lato;
$h3--font-size: 21px;
$h3--font-size-xs: 17px;
$h3--font-weight: 600;
$h3--padding: 0px 0px 10px 0px;
$h3--text-shadow: none;
$h3--text-align: left;
$h3--margin: 30px 0px 20px 0px !important;

$font-size-h1: 48px;
$font-shadow-h1: 1px 1px 0 #fff;
$font-color-h1: #bb2731;
$font-weight-h1: 500;

$main--font-size-xs: 14px !important;

$font-size-h2: 18px;
$font-color-h2: #8e1a22;
$font-margin-h2: 0 0 10px 0;
$font-weight-h2: 700;

$font-color-h3: #bdd042;
$font-margin-h3: 4px 0 0 0;


$header-bg-verlauf-enabled: false;
$header--bg: #f2f2f2;
$header--height: 250px;

$header__top-area__row--bg: #ffffff;
$header__top-area--height: 100px;
$header__bottom-area__nav--bg: rgba(143, 203, 1, 0.8);
$header__top-area__svg--color: $pink;

$header__bottom-area__nav--bg: #da6ca3;


$main--padding: $header--height 0px 0px 0px;


$nav__ul__li__a--font-family: 'Lato';
$nav__ul__li__a--color: #ffffff !important;
$nav__ul__li__a--link-hover-color: #e0ff97 !important;
$nav__ul__li__a--font-size: 20px;
$nav__ul__li__a--font-weight: 800;
$nav__ul__li__a--text-shadow: -0.5px -0.5px 0 #648e00;


$nav__ul__li__dropdown--bg: rgba(255, 255, 255, 0.9);
$nav__ul__li__dropdown__a--font-family: Lato;
$nav__ul__li__dropdown__a--font-weight: 800;
$nav__ul__li__dropdown__a--font-size: 17px;
$nav__ul__li__dropdown__a--color: #00549d !important;
$nav__ul__li__dropdown__a--link-hover-color: #8fcb01 !important;
$nav__ul__li__dropdown__a--link-hover-bg: rgba(255,255,255,0.5) !important;
$nav__ul__li__dropdown__a--text-align: center !important;
$nav__ul__li__dropdown__a--text-shadow: .5px .5px 0 #ffffff;

$nav__ul__li__dropdown--border-radius: 0;

$nav__ul__active__a--color: #e0ff97;

$navbar-default-link-color: #da6ca3;
$navbar-link-hover-color: #321c0c;

$flexslider__control-nav__active--color: $h1--color;
$flexslider__control-nav__normal--color: $h1--color;
$flexslider__control-nav__hover--color: #fff;

$main__a--color: $hell-braun;
$main__a__hover--color: $dunkel-braun;


$footer__h2--color: #fff;
$footer__h2--font-family: 'Lato';
$footer__h2--font-size: $h2--font-size;
$footer__h2--font-weight: $h2--font-weight;
$footer__h2--line-height: $h2--line-height;
$footer__h2--text-rendering: geometricPrecision;
$footer__h2--text-shadow: 0 -1px #a0376c;


$footer__h3--color: #fff;
$footer__h3--font-family: $h3--font-family;
$footer__h3--font-weight: $h3--font-weight;

$footer__h3--font-size: $h3--font-size;
$footer__h3--text-shadow: 0 -1px #a0376c;

$footer--color: $blau;
$footer__a--color: $blau;
$footer__a__hover--color: #c9c9c9;
$footer__a--font-weight: normal;

$footer--font-family: Lato;
$footer--font-weight: 300;
$footer--font-size: 15px;
$footer--padding: 40px 0px 0px 0px;

$footer--bg: #f2f2f2;
$footer__balken-bottom--background: $dunkel-braun;
$footer__balken-bottom--color: #ffffff !important;
$footer__balken-bottom--text-shadow: none;
$footer__balken-bottom--font-family: Lato;
$footer__balken-bottom--text-shadow: $nav__ul__li__a--text-shadow;

$footer__balken-bottom2--padding: 10px;
$footer__balken-bottom2--background: $hell-braun;


$linkboxen--box-shadow: 0px 0px 20px rgba(50, 28, 12, 0.1);
$linkboxen__name--background: rgba(255, 255, 255, 0.7);
$linkboxen__imgdiv--border: 1px solid #fcfbf7;
$linkboxen__a--color: $pink;
$linkboxen__a--font-family: Lato;
$linkboxen__a--font-size: 30px;
$linkboxen__a--line-height: 50px;
$linkboxen__a--font-weight: 300;

$linkboxen__a-hover--font-size: 40px !default;
$linkboxen__a-hover--line-height: 60px !default;


$rexshop__list--background: rgba(255,255,255,0.5);
$rexshop__list--font-family: Lato;
$rexshop__list--color: $pink;
$rexshop__list--font-size: 30px;
$rexshop__list--line-height: 50px;
$rexshop__list--font-weight: 300;
$rexshop__list__article__preis--color: $dunkel-braun;
$rexshop__list__article__preis--font-weight: bold;

/**
 GENERAL IMPORT
 */
@import "variables";
@import "general/functions";
@import "general/basics";
@import "header/navigation";
@import "header/header";
@import "header/logo";
@import "content/content";
@import "general/schatten";
@import "general/footer";
@import "teaser/teaser";
@import "teaser/flexslider";
@import "general/fontawesome/fontawesome";
$fa-font-path: '../fonts/fontawesome';

@import "general/fontawesome/fa-regular";
@import "general/fontawesome/fa-brands";
@import "general/fontawesome/fa-solid";
@import "modules/textblock";
@import "modules/link-box";
@import "modules/rexshop";
@import "addons/ansprechpartner";
@import "addons/mobilenav";

@import "modules/downloads";

@import "../css/test.css";


.header__top-blueline {
  height: 20px;
  width: 100%;
  background: #00549d;
}

.header__top-area__row {
  background: $header__top-area__row--bg;
  height: $header__top-area--height;
  border-bottom: 20px solid #e0e0e0;

  >.container {
    height: $header__top-area--height;

    display: block;
    position: relative;
    width: auto;
  }

  .header__top-area__row__img {
    height: 100%;
    bottom: 19px;
    position: absolute;
    margin-left: -25px;
  }

  .header__top-area__row__logo {
    height: calc(100% - 25px);
    top: 10px;
    position: absolute;
    margin-left: -180px;
  }
}

.header__top-area__icons {
  div a svg {
    max-height: 40px;
  }
}

.header__top-area__icons__logo {
  height: calc(100% - 30px);
  max-height: 40px;
  display: block;
  position: relative;
  margin-left: 20px;
  img, picture, svg {
    height: 40px;
  }
}

.header__menu__bg {
  position: absolute;
  top: 150px;
  height: 100px;
  background: $pink;
  .header__bottom-area {
    left: 50%;
    transform: translateX(0%) translateY(0%) !important;
    padding: 0px;
    nav {
      transform: translateY(0%) !important;
      border-radius: 0px;
      background: none;
      padding-left: 0px;
      padding-right: 0px;
      border-left: 1px solid #a7df22;
      border-right: 1px solid #7cb000;
      padding-top: 0px;
      margin-top: 8px;
      border-radius: 0px;
      padding-bottom: 0px;
      .navbar-nav {
        height: 100%;
        position: relative;
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }
}

.textblock-devider {
  background: none !important;

  background-size: 100% !important;
  background-repeat: no-repeat !important;
  height: 30px !important;
  width: calc(100% + 30px) !important;
  position: relative;
  margin: 0px !important;
  left: -15px;
  border: 0px !important;
  bottom: -20px !important;

  img {
    width: 100%;
    vertical-align: bottom !important;
    bottom: 0px;
    position: absolute;
  }

}

article {
  padding: 20px;

  ul {
    list-style: none;
    padding: 0;
    margin-left: -15px;
  }
  li {
    padding-left: 1.3em;
  }

  li:before {
    content: '\f054';
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    display: inline-block;
    margin-left: -1.3em; /* same as padding-left set on li */
    width: 1.3em; /* same as padding-left set on li */
    color: $pink;
  }
}

.linkboxen {
  margin-top: 40px;
  .linkbox__name {
    div {
      text-shadow: 0.5px 0.5px 0px #fff;
    }
  }
}

#main__content {
  padding-top: 1px;
  position: relative;
  .main__content__ajax {
    article:nth-child(1){
      @include gradient-y(#e5e5e5, #ffffff, 0%, 100%);
    }
  }
}

.main__teaser {
  padding: unset;
}

.footer__icons {
  font-size: 60px;
  display: flex;

  a {
    flex-grow: 1;
    text-align: right;

    i {
      margin-right: -13px;
    }
  }
}

.balken-bottom {
  color: #ffffff;
  a:link {
    color: #ffffff;
  }
  a:hover {
    color: #f2f2f2;
  }
}

  body {
    margin-top: -4px;
    font-size: 17px;
    background: #e5e5e5;
    color: $body--font-color;
  }

#compo-footer {
  position: absolute;
  bottom: 0px;
  right: 0px;
  max-width: 400px;
  max-height: 335px;
  z-index: 80;
  min-width: 360px;

  .optimizedpicture {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    picture {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .header {
    height: 100px !important;

    .logo {
      height: auto;
      bottom: -28px;
      position: absolute;
      top: unset;
      left: 74px;
      img {
        width: 101px;
      }
    }
    .header__top-area {
      .header__top-area__icons {
        top: 8px !important;
        div {
          a {
            i {
              color: $dunkel-braun;
            }
          }
        }
      }
    }
    .header__bottom-area {
      top: 60px !important;
    }
  }

  #main__content {
    .main__content__ajax {
      .container-fluid {
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }
}


.rexshop__list__article--title {
  bottom: 0px;
  width: 100%;
  text-align: center;
  background: rgba(255,255,255, 0.7);
}

.rexshop__list__article--inhaltsstoffe {
  font-size: 12px;
}

.shoppingcart--items {
  position: absolute;
  top: 16px;
  background: #fff;
  padding: 5px;
  border-radius: 5px;
  line-height: 5px;
  font-size: 11px;
}

footer {
  a {
  }
}

.main__content__ajax {
  >article,
  >div {
    position: relative;
  }
  > .container > *,
  > .container-fluid > * {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 1s ease-in-out 0s, transform 1.0s ease-in-out 0s;
    padding: 0px;
  }
}

.fadeContentIn {
  opacity: 1 !important;
  transform: translateY(0px) !important;
}

.main__content__ajax .no-fade {
  opacity: 1 !important;
  //transform: translateY(0px) !important;
}

.modal-title {
  font-family: $h3--font-family;
  font-weight: $h3--font-weight;
  font-size: $h3--font-size;
  color: $h3--color;
  line-height: $h3--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
}

@include media-breakpoint-up(md) {
  .modal-dialog {
    max-width: 560px;
  }
}

.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {

}
.btn-primary {
  background: $pink;
  border: 0px;
}

.table td, .table th {
  border-top: 1px solid rgba(255,255,255, 0.5);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited,
.btn-primary:focus {
  background: $pinkHover !important;
  border: 0px !important;
  box-shadow: none !important;
}

.clearWkorb,
.btn-secondary {
  background: $hell-braun;
  border: 0px;
}

.clearWkorb:hover,
.clearWkorb:active,
.clearWkorb:visited,
.clearWkorb:focus,
.btn-secondary:hover,
.btn-secondary:active,
.btn-secondary:visited,
.btn-secondary:focus {
  background: $hell-braunHover !important;
  border: 0px !important;
  box-shadow: none !important;
}

/**
 * LAZYLOAD
 */
.blur-up {
  -webkit-filter: blur(5px);
  filter: blur(5px);
  width: calc(100% + 10px) !important;
  height: calc(100% + 10px) !important;
  max-width: unset !important;
  max-height: unset !important;
  top: -5px;
  left: -5px;
  transition: width 0.3s, height 0.3s, top 0.3s, left 0.3s, filter 0.3s;
  transition-timing-function: ease-in-out;
  position: absolute
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
  width: 100% !important;
  height: 100% !important;
  top: 0px;
  left: 0px;
}

#addedToCart {

  .modal-body {
    justify-content: center;
    display: flex;
  }
  .modal-footer {
    justify-content: space-between;
  }
}

.rexshop__list__article--preis {
  font-family: $h2--font-family;
  font-weight: $h2--font-weight !important;
  font-size: $h2--font-size;
  color: $h2--color !important;
  line-height: $h2--line-height;
  width: 100%;
  justify-content: center;
  width: 100%;
  span {
    font-size: 12px;
    font-family: $body--font-family;
    color: $body--font-color !important;
  }
}

.cc-window {
  font-family: $body--font-family !important;
}

.logo__footer {
  max-width: 270px;
}


.slidercontext {
  h2 {
    color: $blau;
    font-size: 30px;
  }
  h3, h4 {
    color: $h1--color !important;
    font-size: 18px !important;
    margin: 0 !important;
    padding: 0;
  }
}

.textblock-fullwidth {
  color: #fff !important;
  h2, h3, h4, h5 {
    color: #fff;
    text-shadow: none;
  }
}


$header-bar-right-label-font-size: 9px;

header {
  .header__top-area__phone,
  .header__top-area__email {
    padding-top: 5px;
    text-align: center;
    span {
      white-space: nowrap;
      font-size: $header-bar-right-label-font-size;
      display: block;
      color: #000000;
    }
    i {
      height: 35px;
    }
  }
  .lang--easy {
    border-left: 1px solid #e5e5e5;
    padding: 0 10px 0 10px;
    a {
      text-align: center;
      img {
        width: 40px;
      }
      span {
        white-space: nowrap;
        font-size: $header-bar-right-label-font-size;
        display: block;
        color: #000000;
      }
    }
  }
  .text--zoom-container {
    padding-top: 0;
    border-left: 1px solid #e5e5e5;
    .text--zoom-icons {
      padding: 0;
      margin: 0;
      width: 90px;
      display: flex;
      justify-content: space-between;
      .text--zoom {
        padding: 0;
        margin: 0;
        &.text--zoom-right {
          padding-left: 0;
        }
        img {
          width: 40px;
        }
      }
    }
    span {
      white-space: nowrap;
      font-size: $header-bar-right-label-font-size;
      display: block;
      text-align: center;
      color: #000000;
    }
  }
}

.verlinkung__box {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  margin: 10px;
  text-align: center;
  padding: 7px;
  h3 {
    margin: 0 0 5px !important;
    color: $h3--color;
    font-weight: 400;
    text-align: center;
    padding-top: 5px;
  }
  > img {
    width: 100px;
    height: 100px;
  }
  .link-row{
    margin-top: 20px;
    a {
      display: flex;
      .spacing-left {
        border-top: 1px solid #c2c2c2;
        width: 100%;
        margin: 0 10px;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        text-align: right;
      }
      span {
        color: #8fcb01;
        font-size: 17px;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
}


article.overlap-bottom {
  > .container {
    z-index: 1;
    position: relative;
  }
  .shadow.shadow-bottom {
    z-index: 0;
  }
}

.scroll-to-top {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 10%;
  transform: translateY(-50%);
  img {
    width: 40px;
    height: 40px;
  }
}


.site-map {
  list-style: none;
  &.site-map--lev1 {
    li > h3 {
      a {
        color: $h3--color;
        font-size: $h3--font-size;
        font-family: $h3--font-family;
        font-weight: $h3--font-weight;
        text-rendering: geometricPrecision;
        line-height: $h3--line-height;
      }
      a:hover,a:hover:link {
        color: #999999;
      }
      padding: 0;
      margin: 0 !important;
    }
    .site-map--lev2 {
      list-style: none;
      margin-bottom: 10px;
      li {
        text-align: center;
        a:hover,a:hover:link {
          color: #999999;
        }
      }
    }
  }
}

header .navbar .navbar-nav > li {
  &:hover, > a:hover {
    ~ .dropdown-menu, .dropdown-menu {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }
}


header > .shadow-outside-bottom {
  z-index: -1;
}

@media screen and (max-width: 1500px){
  .header__top-area__row >.container {
    max-width: 888px;
  }
}
@media screen and (max-width: 1260px){
  .header__top-area__row >.container {
    max-width: 810px;
  }
}
@media screen and (max-width: 1200px){
  .header__top-area__row >.container {
    max-width: 630px;
  }
}



.mobilemenu__button {
  padding: 0;
  top: 5px;
  right: 25px;
  i.fa {
    color: #fff;
    font-size: 30px;
  }
}

.mobilemenu {
  transition: right .3s ease-in-out 0.1s, background .1s ease-in-out 0s;
  background: rgba(0, 0, 0, 0);
  pointer-events: all;
  &.open {
    transition: right .3s ease-in-out 0s, background .1s ease-in-out 0.3s;
    background: rgba(0, 0, 0, .3);
  }
  .mobilemenu__button-close {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #e0e0e0;
    i {
      color: #fff;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .navbar-nav {
    background: #e0e0e0;
    .nav-item > a {
      padding-left: 5px;
      color: #fff;
    }
    .nav-item.no-sub-item a {
      box-shadow: 0 1px 2px #000;
    }
    .dropdown-menu a {
      color: #8fcb01;
    }
  }
}

.yform-container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@include mobile {
  .yform-container {
    width: 100%;
  }
  header.header {
    .header__menu__bg .header__bottom-area nav {
      border: 0;
    }
    .header__top-area__row {
      .header__top-area__row__logo {
        margin-left: 0;
      }
      .header__top-area__row__img {
        margin-left: 0;
        right: 0;
      }
    }
    .header__top-area__phone, .header__top-area__email {
      i {
        height: 40px;
      }
    }
    .header__top-area {
      > .container {
        padding: 0;
      }
      .header__top-area__icons {
        position: relative;
        z-index: 1;
        top: 0 !important;
        left: 0;
        right: 0;
        justify-content: flex-start;
        background: #8fcb01;
        padding: 5px 0;

        .header__top-area__icons__logo {
          display: none;
        }
        .text--zoom-container {
          display: none;
        }

        div a i {
          color: #fff;
        }
        div a span {
          color: #fff;
        }
        .lang--easy {
          border: 0;
          margin: 0;
          a svg {
            display: block;
            path{
              &[class$=st0] {

              }
              &[class$=st1] {
                fill: #fff;
              }
            }
          }
        }
      }
    }
    .header__menu__bg {
      display: none;
    }


    .header__top-area {
      transition: height 0.3s ease-in-out 0s;
      .header__top-area__row {
        transition: height 0.3s ease-in-out 0s;
        > .container {
          transition: height 0.3s ease-in-out 0s;
        }
      }
      .header__top-area__icons {
        div a span {
          //transition: opacity 0.3s ease-in-out 0s;
        }
      }
    }

    &.mobile--small {
      .header__top-area {
        height: 70px;
        .header__top-area__row {
          height: 70px;
          > .container {
            height: 70px;
          }
        }
        .header__top-area__icons {
          div a span {
            //animation: smoothHeight 5s ease 0s;
          }
        }
      }
    }
  }
  .main__content__ajax {
    >.container, >.container-fluid {
      > .container {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .empty-gallery {
    display: none;
  }
  .persoenliche-beratung--entry {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.no-ani {
  &, * {
    transition: none !important;
  }
}
